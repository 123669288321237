<template>
  <div v-if="visibility">
    <!-- Header -->
    <v-container fluid class="pa-0">
      <v-row no-gutters style="height: 25vh; background: #d40643" align="center">
        <v-col cols="12">
          <v-card color="transparent" flat dark>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="text-center centerNameEN" v-if="current_lang === 'en'">
                  {{ $vuetify.lang.t(`$vuetify.ticket_query.center_name`) }}
                </div>
                <div class="text-center centerNameZH" v-else>
                  {{ $vuetify.lang.t(`$vuetify.ticket_query.center_name`) }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col cols="12">
                <div class="text-center centerAddr">
                  {{ $vuetify.lang.t(`$vuetify.ticket_query.address_1`) }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="text-center centerAddr">
                  {{ $vuetify.lang.t(`$vuetify.ticket_query.address_2`) }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Body -->
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card flat>
            <!-- Language Buttons -->
            <v-img @click="changeLang('zhHans')" v-if="current_lang === 'zhHant'" class="langButton" :src="require('@/assets/images/cnLangBtn.png')" width="12vw"></v-img>
            <v-img @click="changeLang('zhHant')" v-else class="langButton" :src="require('@/assets/images/tcLangBtn.png')" width="12vw"></v-img>
            <v-img @click="changeLang('en')" class="langButton langButtonEN" :src="require('@/assets/images/enLangBtn.png')" width="12vw"></v-img>

            <!-- Ticket No. -->
            <v-row>
              <v-col cols="12" class="pb-0">
                <div class="text-center ticketCode">{{ ticket.code }}</div>
              </v-col>
            </v-row>

            <!-- Ticket Info -->
            <v-row justify="center">
              <div class="ticketDatetime">{{ $vuetify.lang.t(`$vuetify.ticket_query.ticket_date_time`, $moment(this.ticket.create_datetime).format('YYYY/MM/DD HH:mm:ss')) }}</div>
            </v-row>

            <v-row justify="center" class="mt-2">
              <div :class="{ queueDetailEN: current_lang === 'en', queueDetailZH: current_lang !== 'en' }">
                {{ $vuetify.lang.t(`$vuetify.ticket_query.waiting_count_1`) }}
                <div class="text-decoration-underline font-weight-black" style="display: inline">{{ position }}</div>
                {{ $vuetify.lang.t(`$vuetify.ticket_query.waiting_count_2`) }}
              </div>
            </v-row>

            <!-- Update Time -->
            <v-row>
              <v-col cols="12">
                <div class="text-center ticketDatetime">{{ $vuetify.lang.t(`$vuetify.ticket_query.update_time`, $moment().format('YYYY/MM/DD HH:mm:ss')) }}</div>
                <div class="text-center ticketDatetime">{{ $vuetify.lang.t(`$vuetify.ticket_query.refresh_prompt`) }}</div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'TicketQuery',
  data() {
    return {
      visibility: false,
      ticket: {},
      position: null
    }
  },
  computed: {
    ...mapState(['i18n']),
    current_lang() {
      return this.i18n.current_lang
    }
  },
  methods: {
    ...mapMutations(['SET_I18N_CURRENT_LANGUAGE']),
    changeLang(target_lang) {
      this.SET_I18N_CURRENT_LANGUAGE(target_lang)
    },
    downloadApp() {
      window.location.href = 'https://aiaprod.appgreen.com/AIA-Connect.html'
    }
  },
  async mounted() {
    const language = this.$route.params.language
    const ticket_id = this.$route.query.query
    if (ticket_id) {
      const response = await this.$api.ticket_query(ticket_id)
      if (response.data.success) {
        this.visibility = true
        this.changeLang(language)
        this.ticket = response.data.data.ticket
        this.position = response.data.data.position
      } else {
        this.$router.push({ path: `/error/${language}` })
      }
    } else {
      this.$router.push({ path: `/error/${language}` })
    }
  }
}
</script>

<style scoped>
.centerNameZH {
  font-size: 3rem;
  font-weight: 900;
}

.centerNameEN {
  margin-top: 0.2rem;
  font-size: 2rem;
  font-weight: 900;
}

.centerAddr {
  font-size: 1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.langButton {
  position: absolute;
  cursor: pointer;
  top: 2vh;
  right: 4vw;
}

.langButtonEN {
  margin-top: 6rem;
}

.ticketCode {
  font-size: 4rem;
  font-weight: 500;
}

.ticketDatetime {
  font-size: 0.9rem;
}

.queueDetailEN {
  font-size: 1rem;
  font-weight: 800;
}

.appTitleZH {
  font-size: 1rem;
  font-weight: 900;
}

.appTitleEN {
  font-size: 1rem;
  font-weight: 900;
}

/* iPhone5 */
@media only screen and (max-width: 375px) {
  .centerNameZH {
    font-size: 2rem;
  }

  .centerNameEN {
    font-size: 1rem;
  }

  .centerAddr {
    font-size: 0.8rem;
  }

  .ticketDatetime {
    font-size: 0.5rem;
  }

  .queueDetailEN {
    font-size: 0.9rem;
  }

  .langButtonEN {
    margin-top: 2rem;
  }
}

/* iPhone6/7/8, Plus and iPhoneX*/
@media only screen and (min-width: 374px) and (max-width: 520px) {
  .centerNameZH {
    font-size: 2.5rem;
  }

  .centerNameEN {
    font-size: 1.5rem;
  }

  .centerAddr {
    font-size: 1rem;
  }

  .langButtonEN {
    margin-top: 2.1rem;
  }

  .ticketDatetime {
    font-size: 0.5rem;
  }
}
</style>
